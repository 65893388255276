import { GenericBannerContainer } from "@components/GenericBannerContainer";
import { Layout } from "@components/Layout"
import { SEO } from "@components/SEO"
import theme from "@styles/theme";
import React, { Fragment } from "react"
import Helmet from "react-helmet";
import { Grid } from "semantic-ui-react";

interface Props{
    data: any
  }

class HyperdrivePage extends React.Component<Props>{
    render(){ 
      return (
          <Fragment>
              <Helmet defer={false}>
                  <script async type="text/javascript" src="https://app.viralsweep.com/vsa-widget-ad4073-136562.js?sid=136562_974232" defer></script>
              </Helmet>
          
          <Layout version="standard">
                  <GenericBannerContainer 
                      padding={{
                          mobile: {
                              top: 100,
                              bottom: 80
                          },
                          desktop: {
                              top: 120,
                              bottom: 100
                          }
                      }} backgroundColor={theme.brand.colors.black}>
                      <Grid centered>
                          <Grid.Row>
                              <div id="vsscript_136562_974232"></div>
                          </Grid.Row>
                      </Grid>
                      
                  </GenericBannerContainer>
            </Layout>
            </Fragment>
        );
    }
  }
  
  export default HyperdrivePage
